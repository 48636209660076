
import {
  defineComponent,
  ref,
  inject,
  nextTick,
  onMounted,
  onUnmounted
} from "vue";
import { useStore } from "@/store/index";
import AdminView from "../AdminManager/View.vue";
import bus from "@/utils/bus";
import { IAuth } from "@/apis/interface/IAuth";
import { RespBodyAuth } from "@/apis/interface/Resp";
import authHandler from "@/utils/auth-handler";

export default defineComponent({
  name: "Info",
  components: {
    AdminView: AdminView
  },
  setup() {
    let adminView = ref(null);
    const apiAuth = inject("apiAuth") as IAuth;

    let store = useStore();
    let admin = store.state.authData.admin;
    let roles = store.state.authData.roles;

    nextTick(() => {
      (adminView.value as any).setData(ref([]), ref(admin));
    });

    onUnmounted(() => {
      bus.off("on-table-row-edit-ok");
    });

    onMounted(() => {
      bus.on("on-table-row-edit-ok", async (id) => {
        const data = (await apiAuth.refreshLogin(id as number)) as RespBodyAuth;
        authHandler.setAuth(data);

        nextTick(() => {
          let a = store.state.authData.admin;
          (adminView.value as any).setData(ref([]), ref(a));
        });
      });
    });

    let clickEdit = () => {
      bus.emit("on-table-row-edit", {
        dataList: [],
        dataRow: admin
      });
    };

    return {
      adminView,
      clickEdit,
      roles: ref(roles)
    };
  }
});
